import { Controller } from 'stimulus'
import { show, hide } from '../lib/show_hide'

export default class extends Controller {
  static targets = [
    'button', 'loadingIndicator'
  ]

  waiting() {
    hide(this.buttonTarget)
    show(this.loadingIndicatorTarget)
  }
}
