import { Controller } from 'stimulus'
import camelCaseIt from '../lib/camel_case_it'

export default class extends Controller {
  static targets = ['body']

  connect() {
    this.element[camelCaseIt(this.identifier)] = this;
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.hasBodyTarget) {
      this.bodyTarget.classList.toggle('hidden')
    }

    this.element.notificationsDropdown.hide()
  }

  hide() {
    if (this.hasBodyTarget) {
      this.bodyTarget.classList.add('hidden')
    }
  }

}