import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['menuButton', 'campButton', 'sidebar', 'camp']

  showMenu() {
    this.menuButtonTarget.classList.remove('opacity-40')
    this.campButtonTarget.classList.add('opacity-40')

    this.sidebarTarget.classList.remove('hidden')
    this.sidebarTarget.classList.add('flex')
    this.sidebarTarget.classList.add('w-11/12')
    this.campTarget.classList.add('absolute', 'top-0', 'left-11/12', 'w-full', 'opacity-50')
  }

  showCamp() {
    this.menuButtonTarget.classList.add('opacity-40')
    this.campButtonTarget.classList.remove('opacity-40')

    this.sidebarTarget.classList.add('hidden')
    this.sidebarTarget.classList.remove('flex')
    this.sidebarTarget.classList.remove('w-11/12')
    this.campTarget.classList.remove('absolute', 'top-0', 'left-11/12', 'w-full', 'opacity-50')
  }

  adjust(event) {
    if (window.innerWidth >= 768) {
      this.showCamp()
    }
  }

}
