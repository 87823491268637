import { Controller } from 'stimulus'
import camelCaseIt from '../lib/camel_case_it'

export default class extends Controller {

  static targets = ['dropdown']

  connect() {
    this.element[camelCaseIt(this.identifier)] = this;
  }

  hide() {
    if (this.dropdownTargets.length != 0) {
      this.dropdownTarget.classList.add('hidden')
    }
  }

}
