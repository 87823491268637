import { Controller } from 'stimulus'
import isBlank from '../lib/is_blank'
import { show, hide } from '../lib/show_hide'
import { it_is } from '../lib/true_false'

export default class extends Controller {

  static targets = [
    'mainShowButton',
    'form',
    'camptalkField',
    'contentField',
    'parentField',
    'trixToolbar',
    'replyToSection', 'replyToSectionAvatar', 'replyToSectionUsername', 'replyToSectionFloorNumber',
    'replyButtonTurboFrame'
  ]

  static values = {
    id: String,
    talkId: String
  }

  showReplyForm(event) {
    event.preventDefault()

    const replyButton = event.currentTarget

    hide(this.mainShowButtonTarget)

    this.setParentComment(replyButton)

    this.show_or_hide_section_of_reply_reference(replyButton)

    show(this.formTarget)
  }

  reset() {
    hide(this.formTarget)
    show(this.mainShowButtonTarget)
    hide(this.trixToolbarTarget)
    this.hide_and_reset_reply_to_reference_section()
    this.resetCamptalkField()
    this.resetParentCommentField()
  }

  shortLongSwitch() {
    if (this.commentLength > 60) {
      show(this.trixToolbarTarget)
    } else {
      hide(this.trixToolbarTarget)
    }
  }

  // Private

  setParentComment(replyButton) {
    this.parentFieldTarget.value = replyButton.dataset.commentId || ''

    this.correctTurboFrameId(replyButton)
  }

  correctTurboFrameId(replyButton) {
    if (isBlank(this.parentFieldTarget.value)) {
      this.replyButtonTurboFrameTarget.id = `comment-reply-to-camptalk_${this.talkIdValue}`
    } else {
      this.replyButtonTurboFrameTarget.id = `comment-reply-to-comment_${replyButton.dataset.commentId}`
    }
  }

  resetCamptalkField() {
    this.camptalkFieldTarget.value = this.talkIdValue
  }

  resetParentCommentField() {
    this.parentFieldTarget.value = this.idValue
  }

  show_or_hide_section_of_reply_reference(replyButton) {
    if (it_is(replyButton.dataset.forShort)) {
      this.addReplyToReferenceSection(replyButton)
    } else {
      this.hide_and_reset_reply_to_reference_section()
    }
  }

  hide_and_reset_reply_to_reference_section() {
    hide(this.replyToSectionTarget)
    this.replyToSectionAvatarTarget.innerHTML = ''
    this.replyToSectionUsernameTarget.innerHTML = ''
    this.replyToSectionFloorNumberTarget.innerHTML = ''
    const replyToSectionPadding = this.contentFieldTarget.dataset.replyToSectionPadding
    this.contentFieldTarget.classList.remove(replyToSectionPadding)
  }

  addReplyToReferenceSection(replyToShortbutton) {
    this.replyToSectionAvatarTarget.innerHTML = this.element.querySelector(`#avatar-on-comment-${replyToShortbutton.dataset.commentId}`).innerHTML
    this.replyToSectionUsernameTarget.innerHTML = replyToShortbutton.dataset.commentAuthor
    this.replyToSectionFloorNumberTarget.innerHTML = `#${replyToShortbutton.dataset.commentFloorNumber}`
    const replyToSectionPadding = this.contentFieldTarget.dataset.replyToSectionPadding
    this.contentFieldTarget.classList.add(replyToSectionPadding)
    show(this.replyToSectionTarget)
  }

  get commentLength() {
    let comment = this.contentFieldTarget.editor.getDocument().toString()
    comment.replace(/\n/, "")
    return comment.length
  } 

}
