import { Controller } from 'stimulus'

export default class extends Controller {

  static values = {
    urls: Array
  }

  static targets = ['avatar', 'month']

  connect() {
    setInterval(() => {
      const urls = this.urlsValue.sort(() => Math.random() - 0.5)
      for (let i = 0; i < urls.length; i++) {
        this.avatarTargets[i].src = urls[i]
      }

      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const currentMonth = this.monthTarget.innerHTML
      const nextIndex = (months.indexOf(currentMonth) + 1) % 12
      this.monthTarget.innerHTML = months[nextIndex]
    }, 1500);
  }

}