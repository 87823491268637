import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
    "field",
    "form"
  ]

  select() {
    this.fieldTarget.click()
  }

  upload() {
    this.formTarget.submit()
  }

}
