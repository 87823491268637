import { Controller } from 'stimulus'
import { show, hide } from '../lib/show_hide'

export default class extends Controller {

  static targets = [ 'ticket', 'loadingIndicator' ]

  waiting() {
    hide(this.ticketTarget)
    show(this.loadingIndicatorTarget)
  }

}
