const is_false = (object) => {
  return [false, 'false', null, undefined, NaN].includes(object)
}

const is_true = (object) => {
  return !is_false(object)
}

const it_is = (object) => {
  return is_true(object)
}

export {
  is_true,
  is_false,
  it_is
}