import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['turboCableStreamSource']

  toggle() {
    setTimeout(function() {
      const turboCableStreamSource = this.turboCableStreamSourceTarget
      const subscription = turboCableStreamSource.subscription
      const consumer = subscription.consumer

      subscription.unsubscribe()
      if (consumer.subscriptions.subscriptions.length === 0) {
        consumer.disconnect()
      }

      location.reload()
    }.bind(this), 10000)
  }
}
