import { Controller } from 'stimulus'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/themes/light.css';


export default class extends Controller {

  static targets = ['button']

  connect() {
    const controller = this

    if (controller.hasButtonTarget) {
      tippy(this.element, {
        content()  {
          return controller.buttonTarget.innerHTML
        },
        interactive: true,
        allowHTML: true,
        theme: 'light'
      })
    }
  }

}
