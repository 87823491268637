import { Controller } from "stimulus";
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/themes/light.css';


export default class extends Controller {

  static targets = ['message', 'button']
  static values = {
    on: Boolean
  }

  connect() {
    const controller = this

    if (this.onValue) {
      tippy(this.buttonTarget, {
        content()  {
          return controller.messageTarget.innerHTML
        },
        allowHTML: true,
        theme: 'light'
      })
    }
  }

}
