import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
    'field',
    'label'
  ]

  count() {
    let comment = this.fieldTarget.editor.getDocument().toString()
    const commentLength = comment.replace(/\n/, "").length
    this.labelTarget.innerHTML = commentLength
  }

}
